import React, { useState } from "react";
import "./ImageCarouselItem.scss";
import ReactLoading from "react-loading";

type Props = {
  image: { imageURL: string };
};

const ImageCarouselItem: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className={"image_carousel_item"}>
      {!loading && (
        <img
          onLoadStart={() => setLoading(true)}
          onLoadedData={() => setLoading(false)}
          src={props.image.imageURL}
          loading={"lazy"}
        />
      )}
      {loading && (
        <ReactLoading type={"cylon"} color={"red"} height={50} width={50} />
      )}
    </div>
  );
};

export default ImageCarouselItem;
