import React from "react";
import "./Header.scss";
import VerticalBorder from "./VerticalBorder";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={"header"}>
      <div className={"nav_item_container"} onClick={() => navigate("/")}>
        <h2>Home</h2>
      </div>
      <VerticalBorder />
      <div
        className={"nav_item_container"}
        onClick={() =>
          window.open("https://www.instagram.com/_djordjejanjic/", "mywindow")
        }
      >
        <h2>Instagram</h2>
      </div>
      <VerticalBorder />
      <div
        className={"nav_item_container"}
        onClick={() => navigate("/equipment")}
      >
        <h2>Equipment</h2>
      </div>
    </div>
  );
};

export default Header;
