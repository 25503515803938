import React, { Fragment, useEffect, useRef, useState } from "react";
import "./OpenMap.scss";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { LatLngBounds, PointTuple } from "leaflet";
import marker from "../../assets/treasure-spot.png";
import marker1 from "../../assets/pngfind1.png";
import marker2 from "../../assets/pngfind2.png";
import marker3 from "../../assets/pngfind3.png";
import marker4 from "../../assets/pngfind4.png";
import marker5 from "../../assets/pngfind6.png";
import marker6 from "../../assets/pngfind7.png";
import Carousel from "react-material-ui-carousel";
import ImageCarouselItem from "../ImageCarousel/ImageCarouselItem/ImageCarouselItem";
import { Button, useRadioGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../common/url/urls";
import ReactLoading from "react-loading";

const OpenMap: React.FC = (props) => {
  const navigate = useNavigate();

  const lngLtdRef = useRef<any>({ lngLtd: [42.4165, 16.0059] });

  const [zoom, setZoom] = useState<number | undefined>(5);
  const [iconSize, setIconSize] = useState<PointTuple>([35, 35]);
  const [galleries, setGalleries] = useState<any[]>([]);
  const [displayedGalleries, setDisplayedGalleries] = useState<any[]>([]);
  const [galleryImages, setGalleryImages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [clickedLngLtd, setClickedLngLtd] = useState<any>([]);
  const [icons, setIcons] = useState<any>([]);

  let icon1 = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    popupAnchor: [-0, -25],
    iconSize: iconSize,
    className: "custom_icon",
  });
  let icon2 = new L.Icon({
    iconUrl: marker2,
    iconRetinaUrl: marker2,
    popupAnchor: [-0, -25],
    iconSize: iconSize,
    className: "custom_icon",
  });

  let icon3 = new L.Icon({
    iconUrl: marker3,
    iconRetinaUrl: marker3,
    popupAnchor: [-0, -25],
    iconSize: iconSize,
    className: "custom_icon",
  });

  let icon4 = new L.Icon({
    iconUrl: marker4,
    iconRetinaUrl: marker4,
    popupAnchor: [-0, -25],
    iconSize: iconSize,
    className: "custom_icon",
  });

  let icon5 = new L.Icon({
    iconUrl: marker5,
    iconRetinaUrl: marker5,
    popupAnchor: [-0, -25],
    iconSize: iconSize,
    className: "custom_icon",
  });

  let icon6 = new L.Icon({
    iconUrl: marker6,
    iconRetinaUrl: marker6,
    popupAnchor: [-0, -25],
    iconSize: iconSize,
    className: "custom_icon",
  });

  const getGalleries = async () => {
    try {
      setLoading(true);
      const response = await axios.get(BASE_URL + "/gallery");

      const display: any = [];

      response.data.forEach((gallery: any) => {
        if (!display.length) display.push(gallery);
        display.forEach((displayedGallery: any, index: number) => {
          if (
            Math.abs(
              parseFloat(gallery.longitude) -
                parseFloat(displayedGallery.longitude)
            ) < 1 &&
            Math.abs(
              parseFloat(gallery.latitude) -
                parseFloat(displayedGallery.latitude)
            ) < 1
          ) {
            return;
          }
          if (index === display.length - 1) {
            display.push(gallery);
          }
        });
      });

      setDisplayedGalleries(display);
      setGalleries(response.data);

      response.data.forEach((gallery: any, index: number) => {
        if (index % 2 === 0 && index % 3 !== 0 && index % 5 !== 0) {
          setIcons((prevState: any) => [...prevState, icon1]);
        }
        if (index % 3 === 0 && index % 2 !== 0 && index % 5 !== 0) {
          setIcons((prevState: any) => [...prevState, icon2]);
        }
        if (index % 5 === 0 && index % 3 !== 0 && index % 2 !== 0) {
          setIcons((prevState: any) => [...prevState, icon3]);
        }
        setIcons((prevState: any) => [...prevState, icon4]);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getGalleryImages = async (id: number, lng: string, ltd: string) => {
    try {
      const response = await axios.get(BASE_URL + "/gallery/" + id);
      setGalleryImages(response.data.images);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (zoom) {
      setIconSize([200 / (12 - zoom), 200 / (12 - zoom)]);
    }
  }, [zoom]);

  useEffect(() => {
    getGalleries();
  }, []);

  return (
    <>
      {loading && (
        <div className={"loader-wrapper"}>
          <ReactLoading
            className={"loader"}
            type={"spin"}
            color={"#2C3639"}
            height={100}
            width={100}
          />
        </div>
      )}
      {!loading && (
        <div className={"open_street_map"}>
          <MapContainer
            closePopupOnClick={true}
            style={{ width: "100%", height: "100%" }}
            doubleClickZoom={false}
            ref={(ref) => {
              ref?.addEventListener("zoom", () => {
                setZoom(ref?.getZoom());
              });
              clickedLngLtd.length > 0 &&
                ref?.latLngToLayerPoint(clickedLngLtd);
            }}
            center={[37.4165, 10.0059]}
            zoom={5}
            maxZoom={10}
            minZoom={3}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https:///cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
            />
            {/* <img
              style={{
                position: "absolute",
                zIndex: 999,
                opacity: 0.5,
                height: "100%",
                width: "100%",
                objectFit: "cover",
                transform: `scale(${zoom ? zoom / (zoom - 1) : 1})`,
                transition: "0.4s ease",
                mixBlendMode: "multiply",
                pointerEvents: "none",
              }}
              src={require("../../assets/map-texture.jpg")}
            /> */}
            {galleries?.map(
              (
                gallery: {
                  id: number;
                  name: string;
                  longitude: string;
                  latitude: string;
                  images: [];
                },
                index: number
              ) =>
                gallery?.images?.length > 0 &&
                (zoom &&
                zoom < 6 &&
                !displayedGalleries.find((el: any) => el.id === gallery.id) ? (
                  <Fragment key={"invisible_" + gallery.id}></Fragment>
                ) : (
                  <Marker
                    key={"marker_" + gallery.id + "_" + gallery.latitude}
                    icon={icon1}
                    eventHandlers={{
                      click: async (e) => {
                        setClickedLngLtd([
                          parseFloat(gallery.longitude) - 2,
                          parseFloat(gallery.latitude),
                        ]);
                        await getGalleryImages(
                          gallery.id,
                          gallery.longitude,
                          gallery.latitude
                        );
                      },
                      popupclose: async () => {
                        setGalleryImages([]);
                      },
                    }}
                    position={[
                      parseFloat(gallery.longitude),
                      parseFloat(gallery.latitude),
                    ]}
                  >
                    <Popup className={"custom_popup"} closeButton={false}>
                      <Button
                        variant={"outlined"}
                        className={"custom_button"}
                        onClick={() => navigate("/gallery/" + gallery.id)}
                      >
                        View gallery - {gallery.name}
                      </Button>
                      {galleryImages?.length > 0 && (
                        <Carousel>
                          {galleryImages
                            ?.slice(0, 6)
                            .map((image: any, i: number) => (
                              <ImageCarouselItem
                                key={"image_" + i}
                                image={image}
                              />
                            ))}
                        </Carousel>
                      )}
                    </Popup>
                  </Marker>
                ))
            )}
          </MapContainer>
        </div>
      )}
    </>
  );
};

export default OpenMap;
