import React from "react";
import './VerticalBorder.scss';

type Props = {

}

const VerticalBorder:React.FC<Props> = (props: Props) => {
    return (
        <div className={'vertical_border'} />
    )
}

export default VerticalBorder;