import React from "react";
import "./Equipment.scss";

const Equipment: React.FC = (props) => {
  return (
    <div className={"equipment"}>
      <img src={require("../../assets/world-map.jpg")} />
      <div className={"equipment-info"}>
        <h1>Equipment</h1>
        <h2>Cameras</h2>
        <p>Fujifilm X100s</p>
        <p>Sony A7 III</p>
        <h2>Lenses</h2>
        <p>Sony 28-70mm FE OSS</p>
        <p>Sony 85mm f1.8</p>
      </div>
    </div>
  );
};

export default Equipment;
