import React from "react";
import "./Home.scss";
import OpenMap from "../Map/OpenMap";

const Home: React.FC = () => {
  return (
    <div className={"home"}>
      <OpenMap />
    </div>
  );
};

export default Home;
